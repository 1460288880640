
















































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import BootstrapBreakpointsLabels from '@/utils/enums/BootstrapBreakpointsLabels';
import InputText from '@/components/InputText.vue';
import CommunityUserTag from '@/models/graphql/CommunityUserTag';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

const communityUserTagStore = namespace('CommunityUserTagStore');

@Component({
  data(): object {
    return {
      localTagName: '',
    };
  },
  components: {
    FontAwesomeComponent,
    StandardModal,
    ButtonComponent,
    InputText,
    ButtonIconComponent,
  },
})
export default class TagCreateEditModal extends Vue {
  @Prop({ default: '' })
  modalId!: string;

  @Getter
  protected readonly authUser!: CommunityUser;

  @Prop()
  private readonly currentBootstrapBreakpoint!: BootstrapBreakpointsLabels;

  @Prop({ required: false, default: null })
  private readonly tagToEdit!: CommunityUserTag;

  @Prop({ required: false, default: [] })
  private readonly currentTagList!: CommunityUserTag[];

  @communityUserTagStore.Action
  private createForUser!: (payload: {
    userUid: string;
    entity: Partial<CommunityUserTag>;
  }) => Promise<void | CommunityUserTag>;

  @communityUserTagStore.Action
  private updateCommunityUserTag!:
    (payload: Partial<CommunityUserTag>) => Promise<CommunityUserTag | undefined>;

  private saveLoading = false;

  private get isMobile(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'sm' || this.currentBootstrapBreakpoint === 'xs');
  }

  private get isTablet(): boolean {
    return this.currentBootstrapBreakpoint
      && (this.currentBootstrapBreakpoint === 'md');
  }

  private get tagAlreadyExist(): boolean {
    const filteredList = this.tagToEdit
      ? this.currentTagList.filter((tag) => tag.uid !== this.tagToEdit.uid)
      : this.currentTagList;
    const found = filteredList.find((tag: CommunityUserTag) => tag.name === this.$data.localTagName);
    return !!found;
  }

  @Watch('tagToEdit')
  private onTagToEditChange(): void {
    if (this.tagToEdit) {
      this.$data.localTagName = this.tagToEdit.name;
    } else {
      this.$data.localTagName = '';
    }
  }

  private onCancel(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onSaveTag(): void {
    this.saveLoading = true;
    if (this.$data.localTagName.length) {
      if (this.tagToEdit) {
        const editedTag = {
          uid: this.tagToEdit.uid,
          name: this.$data.localTagName,
        } as CommunityUserTag;
        this.updateCommunityUserTag(editedTag).then((response) => {
          if (response) {
            this.saveLoading = false;
            this.$emit('on-edit',
              { newName: this.$data.localTagName, uid: response.uid });
            this.onCancel();
          }
          this.saveLoading = false;
        });
      } else {
        const newTag = {
          name: this.$data.localTagName,
        } as CommunityUserTag;
        this.createForUser({
          userUid: this.authUser.uid,
          entity: newTag,
        }).then((response) => {
          if (response) {
            this.saveLoading = false;
            this.$emit('on-create',
              { newName: this.$data.localTagName, uid: response.uid });
            this.onCancel();
          }
          this.saveLoading = false;
        });
      }
    }
    this.saveLoading = false;
    this.onCancel();
  }

  private onHide(): void {
    this.$emit('on-modal-hide');
  }
}
